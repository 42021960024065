export default {
  tooltip: {
    trigger: 'axis',
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (
          item.seriesName === '收益' ||
          item.seriesName === 'eCPM' ||
          item.seriesName === '预估收益' ||
          item.seriesName === 'ARPDAU'
        ) {
          temp.value = '￥' + item.value
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    data: []
  },
  grid: {
    left: '3%',
    right: '7%',
    bottom: '4%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    data: [],
    boundaryGap: false,
    axisLabel: {
      show: true
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      name: '',
      position: 'left',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#666',
          width: 0.5
        }
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: true
      },

      axisLabel: {
        show: true
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 6, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: '#ffab31',
          lineStyle: {
            color: '#ffab31',
            width: 2
          }
        }
      }
    }
  ]
}
